<template>
  <li class="row flex-nowrap p10 m0 relative">
    <div class="bg-cl-secondary loader-box flex" v-if="loader">
      <loading-spinner />
    </div>
    <div class="flex image-product">
      <div class="image-box bg-cl-white">
        <img class="image" v-lazy="image" alt="">
      </div>
    </div>
    <div class="flex product-info-box between-xs">
      <div class="flex pl10 start-xs between-sm details w-40 title-product">
        <div>
          <div class="h4 title-font name cl-black weight-700 uppercase">
            {{ product.name | htmlDecode }}
          </div>
          <div class="properties-box">
            <div class="prices" v-if="!displayItemDiscounts">
              {{ $t('Price') }}:
              <span class="h4 h3-sm cl-error price-special" v-if="product.special_price">
                {{ product.price_incl_tax * product.qty | price }}&nbsp;
              </span>
              <span class="h6 price-original" v-if="product.special_price">
                {{ product.original_price_incl_tax * product.qty | price }}
              </span>
              <span class="h4 h3-sm price-regular cl-black" v-if="!product.special_price" data-testid="productPrice">
                {{ product.price_incl_tax * product.qty | price }}
              </span>
            </div>
            <div class="prices" v-if="product.totals && displayItemDiscounts">
              {{ $t('Price') }}:
              <span class="h4 h3-sm cl-error price-special" v-if="product.totals.discount_amount">
                {{ product.totals.row_total_incl_tax - product.totals.discount_amount | price }}&nbsp;
              </span>
              <span class="h6 price-original" v-if="product.totals.discount_amount">
                {{ product.totals.row_total_incl_tax | price }}
              </span>
              <span class="h4 h3-sm price-regular cl-black" v-if="!product.totals.discount_amount">
                {{ product.totals.row_total_incl_tax | price }}
              </span>
            </div>
            <div class="prices" v-else>
              <span class="h4 h3-sm serif price-regular cl-black">
                {{ product.regular_price * product.qty | price }}
              </span>
            </div>
            <div class="h6 cl-black options" v-if="product.totals && product.totals.options">
              <div v-for="opt in product.totals.options" :key="opt.label">
                <span class="opn">{{ $t(opt.label) }}</span>
                <span class="opv" v-html="opt.value" />
              </div>
            </div>
            <div class="h5 cl-black options" v-else-if="product.options">
              <div v-for="opt in product.options" :key="opt.label" class="my5">
                <span v-if="opt.label === 'Size'" class="opv" v-html="sizeBeautifier(opt.value)" />
                <span v-else class="opv" v-html="opt.value" />
              </div>
            </div>
            <div class="h5 cl-black options" v-if="product.shoe_size">
              <div class="my5">
                <span class="cl-black">{{ getAttributeLabel('shoe_size') }}:</span>
                <span class="opn cl-light-gray weight-900">{{ getAttributeLabelName('shoe_size') }}</span>
              </div>
            </div>
            <div v-if="!isSimple" class="cl-black h5">
              <span class="cl-black">{{ $t('Hire') }}:</span>
              <span class="opn cl-light-gray weight-900">{{ getPreatyPeriod }}</span>
            </div>
            <div class="h6 mt5 cl-error" v-if="product.errors && Object.keys(product.errors).length > 0">
              {{ product.errors | formatProductMessages }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex pl15 align-right start-xs between-sm actions w-32">
        <div class="links" :class="{ 'hidden-xs' : !isEdge }">
          <div class="ff-semi" @click="removeItem">
            <remove-button />
          </div>
        </div>
        <div class="h5 cl-accent lh25 qty flex-center">
          <number-input
            class="ff-light"
            :class="{ 'hidden-xs' : !isEdge }"
            :value="product.qty"
            @click="updateQuantity"
            @blur="updateQuantity"
          />
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import config from 'config'
import LoadingSpinner from 'theme/components/core/LoadingSpinner.vue'
import rootStore from '@vue-storefront/core/store'
import Product from '@vue-storefront/core/compatibility/components/blocks/Microcart/Product'
import RemoveButton from './RemoveButton'
import NumberInput from 'theme/components/core/NumberInput'
import { BrowserDetected } from 'theme/components/core/BrowserDetected.ts'
import { getThumbnailForProduct } from '@vue-storefront/core/modules/cart/helpers'
import { mapGetters } from 'vuex'

export default {
  components: {
    RemoveButton,
    NumberInput,
    LoadingSpinner
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  mixins: [Product, BrowserDetected],
  data () {
    return {
      displayItemDiscounts: rootStore.state.config.cart.displayItemDiscounts,
      loader: false
    }
  },
  computed: {
    ...mapGetters({
      attributeListByCode: 'attribute/attributeListByCode'
    }),
    image () {
      return {
        loading: this.thumbnail,
        src: this.thumbnail
      }
    },
    thumbnail () {
      return this.getThumbnail(this.product.image, config.entities.product.productSmall.width, config.entities.product.productSmall.height)
    },
    getPreatyPeriod () {
      if (this.getPeriod === 2) {
        return this.$t('Trying on')
      } else {
        return this.getPeriod === 1
          ? this.getPeriod + ' ' + this.$t('day')
          : this.getPeriod + 1 + ' ' + this.$t('days')
      }
    },
    getPeriod () {
      if (!this.product.totals || !this.product.totals.base_price_incl_tax || !this.product.sirental_price) return null
      let currType = this.product.sirental_price.find(type => type.price && Number(type.price) === this.product.totals.base_price_incl_tax)
      let plusOneDayToCurrType = currType && currType.period.split('')
      return plusOneDayToCurrType
        ? Number(plusOneDayToCurrType[0])
        : 0
    },
    isSimple () {
      return this.product.type_id === 'simple'
    }
  },
  methods: {
    removeFromCart () {
      this.$store.dispatch('cart/removeItem', { product: this.product })
    },
    changeLoader (bool) {
      this.loader = bool
    },
    sizeBeautifier (size) {
      if (size === 'X') {
        return 'ONE SIZE'
      } else if (size && size.length) {
        if (size.indexOf('1/3') !== -1) {
          let whole = size.replace('1/3', '')
          return whole + ' ' + '<sup>1</sup>&frasl;<sub>3</sub>'
        } else if (size.indexOf('2/3') !== -1) {
          let whole = size.replace('2/3', '')
          return whole + ' ' + '<sup>2</sup>&frasl;<sub>3</sub>'
        } else {
          return size
        }
      } else {
        return size
      }
    },
    getAttributeLabel (value) {
      return this.attributeListByCode[value] ? this.attributeListByCode[value].frontend_label : ''
    },
    getAttributeLabelName (type) {
      if (this.attributeListByCode && this.attributeListByCode[type]) {
        let items = this.attributeListByCode[type].options.filter((option) => { return option.value === String(this.product[type]) })
        if (items && items.length) {
          return items[0].label
        } else {
          return false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loader-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.6;
}
.prices {
  font-size: 14px;
  color: #000000;
}
.image-product {
  flex-basis: 30%;
  justify-content: center;
  .image-box {
    display: flex;
    align-items: center;
  }
}
.opv {
  font-weight: 800;
  color: #707070;
}
.title-product {
  @media (max-width: 767px) {
    justify-content: center;
  }
}
 @media (min-width: 768px) {
  .title-product {
    justify-content: center;
  }
  .actions {
    justify-content: space-around;
  }
  .h3-sm {
    font-size: 24px;
  }
 }
.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 150px;
}
.flex-center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
  .details {
    flex-direction: column;
    @media (max-width: 767px) {
      // padding: 0 10px 0 20px;
    }
  }
  .name {
    @media (max-width: 767px) {
      overflow: hidden;
      font-size: 14px;
      padding-bottom: 5px;
    }
  }
  .options, .sku {
    @media (max-width: 767px) {
      div {
        margin: 0
      }
      font-size: 14px;
    }
  }
  .qty {
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
  .actions {
    flex-direction: column;
    @media (max-width: 767px) {
      justify-content: flex-end;
      padding-left: 0;
      font-size: 12px;
    }
    .links {
      @media (min-width: 768px) {
        display: block;
      }
      @media (max-width: 767px) {
        font-size: 0;
      }
    }
  }
  .price-special {
    @media (max-width: 767px) {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .price-original {
    text-decoration: line-through;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .price-regular {
    @media (max-width: 767px) {
      font-size: 14px;
      color: #707070;
      font-weight: 600;
    }
  }
  input {
    width: 30px;
  }
  .flex-nowrap {
    flex-wrap: nowrap;
  }
  .single-price {
    @media (max-width: 767px) {
      font-size: 12px;
      // margin-left: 25px;
    }
  }
  .properties-box {
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      // margin-bottom: 10px;
    }
  }
  .product-info-box {
    flex: 1
  }
</style>
